import { type DailyTaskTeam } from "@sgdf/client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { meetingService } from "@/core/services/meeting.service";
import { useDailyTaskTeamId, useMeetingId } from "./routes";

const getQueryKey = (meetingId: string) => [
  "meetings",
  meetingId,
  "daily-task-teams",
];

const getInvalidatePredicate = (meetingId: string) => (query: any) => {
  return (
    query.queryKey[0] === "meetings" &&
    query.queryKey[1] === meetingId &&
    typeof query.queryKey[2] === "string" &&
    ["daily-task-teams", "participants"].includes(query.queryKey[2])
  );
};

export function useDailyTaskTeam() {
  const meetingId = useMeetingId();
  const dailyTaskTeamId = useDailyTaskTeamId();
  return useQuery({
    queryKey: [...getQueryKey(meetingId), dailyTaskTeamId],
    queryFn: () =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsRetrieve({
          meetingUuid: meetingId,
          uuid: dailyTaskTeamId,
        }),
  });
}

export function useCreateDailyTaskTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { team: DailyTaskTeam }) =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsCreate({
          meetingUuid: meetingId,
          notNestedDailyTaskTeam: options.team,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(meetingId),
      });
    },
  });
}

export function useUpdateDailyTaskTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { team: DailyTaskTeam }) =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsPartialUpdate({
          meetingUuid: meetingId,
          uuid: options.team.id,
          patchedDailyTaskTeam: options.team,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: getInvalidatePredicate(meetingId),
      });
    },
  });
}

export function useDeleteDailyTaskTeamMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { id: string }) =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsDestroy({
          meetingUuid: meetingId,
          uuid: options.id,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: getInvalidatePredicate(meetingId),
      });
    },
  });
}

export const useDailyTaskTeams = (force?: boolean) => {
  const meetingId = useMeetingId();
  const role = useRole();
  const enabled = computed(() => !!meetingId && !!role.value?.isFormer);

  return useQuery({
    queryKey: getQueryKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsList({
          meetingUuid: meetingId,
        }),
    enabled: enabled.value || force,
  });
};

export function useUpdateDailyTaskTeamParticipantMutation() {
  const meetingId = useMeetingId();

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (options: { teamId: string; participants: string[] }) =>
      meetingService
        .getInstance()
        .getDailyTaskTeamApi()
        .meetingsDailyTaskTeamsParticipantsUpdate({
          meetingUuid: meetingId,
          uuid: options.teamId,
          participantsDailyTaskTeam: { participants: options.participants },
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: getInvalidatePredicate(meetingId),
      });
    },
  });
}
